import slugifyPro from 'slugify';
import _ from './utils';
export function slugify(slug: string, replacement = '-') {
  // return slug.split(/\s/).map(s => s.trim()).filter(s => s.length > 0).join(join);
  return slugifyPro(slug, {
    locale: 'fr',
    lower: true,
    trim: true,
    replacement,
    remove: /'/g
  })
}

export function UpperFirst(string: string) {
  return string.length > 0 ? `${string.charAt(0).toLocaleUpperCase()}${string.substring(1)}` : ''
}

export function getPseudo() {
  const adjectives = [
    "adorable",
    "beautiful",
    "clean",
    "drab",
    "elegant",
    "fancy",
    "glamorous",
    "handsome",
    "long",
    "magnificent",
    "old-fashioned",
    "plain",
    "quaint",
    "sparkling",
    "ugliest",
    "unsightly",
    "angry",
    "bewildered",
    "clumsy",
    "defeated",
    "embarrassed",
    "fierce",
    "grumpy",
    "helpless",
    "itchy",
    "jealous",
    "lazy",
    "mysterious",
    "nervous",
    "obnoxious",
    "panicky",
    "repulsive",
    "scary",
    "thoughtless",
    "uptight",
    "worried"
  ];

  const nouns = [
    "women",
    "men",
    "children",
    "teeth",
    "feet",
    "people",
    "leaves",
    "mice",
    "geese",
    "halves",
    "knives",
    "wives",
    "lives",
    "elves",
    "loaves",
    "potatoes",
    "tomatoes",
    "cacti",
    "foci",
    "fungi",
    "nuclei",
    "syllabuses",
    "analyses",
    "diagnoses",
    "oases",
    "theses",
    "crises",
    "phenomena",
    "criteria",
    "data"
  ];

  const adjs = _.rand(adjectives)
  const nom = _.rand(nouns)

  return `${adjs}${nom.slice(0, 1).toUpperCase()}${nom.slice(1)}`;
}

export const coalesce = (...args: Array<any>) => {
  return args.find(arg => arg !== null && arg !== undefined && String(arg).trim().length > 0);
}